import {
  toMoneyValue,
  ComputedPaymentTypeValues,
} from '@ads-bread/shared/bread/util';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMerchantConfig } from '../../MerchantContext';
import { useFeatureFlags } from '../../FeatureFlagsContext';
import { useFormattedNumber } from '../../../lib/hooks/useFormattedNumber';
import { FCWithChildren } from '../../../lib/types';
import { formattingValues } from './Intro';

type DisclaimersProps = {
  hasInstallments: boolean;
  hasSplitPay: boolean;
  installmentsPaymentTypeValues?: ComputedPaymentTypeValues | null;
  splitPayPaymentTypeValues?: ComputedPaymentTypeValues | null;
};

export const Disclaimers: FunctionComponent<DisclaimersProps> = ({
  hasInstallments,
  hasSplitPay,
  installmentsPaymentTypeValues,
  splitPayPaymentTypeValues,
}) => {
  const { aprFormatter, formatCurrency } = useFormattedNumber();
  const { disclosureSymbolInstallments, disclosureSymbolSplitpay } =
    useMerchantConfig();
  const { disableColoradoAPR } = useFeatureFlags();

  return (
    <div data-testid="intro-disclaimer">
      <div id="footnoteDisclosureSymbolInstallments">
        <Disclaimer>
          {installmentsPaymentTypeValues && hasInstallments && (
            <FormattedMessage
              defaultMessage="{disclosureSymbolInstallments} Minimum purchase of {minCartSize} required.
                Availability of terms may vary based on {disableColoradoAPR, select, false {purchase price, product, and state of residence} other {purchase price and/or product}}.
                Down payment may be required. Subject to approval of credit application. Rates range from {minInterestRateBPS} to {maxInterestRateBPS} APR, resulting in, for example
                {promoOfferLength} monthly payments of {promoOfferAmount} at {promoOfferRate} APR, per {exampleTotalPrice} borrowed. APRs will vary depending on credit qualifications,
                loan amount, and term. Bread Pay™ loans are made by Comenity Capital Bank, a Bread Financial™ company."
              description="Installment only without product price"
              values={{
                ...formattingValues,
                disableColoradoAPR,
                minCartSize: formatCurrency(
                  toMoneyValue(installmentsPaymentTypeValues.minCartSize)
                ),
                maxCartSize: formatCurrency(
                  toMoneyValue(installmentsPaymentTypeValues.maxCartSize || 0)
                ),
                minInterestRateBPS: aprFormatter(
                  installmentsPaymentTypeValues.minInterestRateBPS
                ),
                maxInterestRateBPS: aprFormatter(
                  installmentsPaymentTypeValues.maxInterestRateBPS
                ),
                promoOfferAmount: formatCurrency(
                  installmentsPaymentTypeValues.promoOfferAmount
                ),
                promoOfferRate: aprFormatter(
                  installmentsPaymentTypeValues.promoOfferRate
                ),
                exampleTotalPrice: formatCurrency(
                  installmentsPaymentTypeValues.exampleTotalPrice
                ),
                promoOfferLength:
                  installmentsPaymentTypeValues.promoOfferLength,
                disclosureSymbolInstallments,
              }}
            />
          )}
        </Disclaimer>
      </div>

      {splitPayPaymentTypeValues && hasSplitPay && (
        <>
          <Disclaimer>
            <span id="footnoteDisclosureSymbolSplitPay">
              <FormattedMessage
                defaultMessage="<sup>{disclosureSymbolSplitpay}</sup> Available for purchases between {minCartSize} and {maxCartSize}. Subject to approval of credit application.
                A temporary authorization hold will be placed on your debit or credit card to ensure your card will cover the first
                two payments. One payment will be immediately voided. Please note, your financial institution may charge you one or more non-sufficient funds or overdraft fees if any
                loan payment exceeds your account's available balance. If you pay with a credit card, your credit card issuer may charge interest and/or fees."
                description="SplitPay disclaimer"
                values={{
                  ...formattingValues,
                  minCartSize: formatCurrency(
                    toMoneyValue(splitPayPaymentTypeValues.minCartSize)
                  ),
                  maxCartSize: formatCurrency(
                    toMoneyValue(splitPayPaymentTypeValues.maxCartSize || 0)
                  ),
                  disclosureSymbolSplitpay,
                }}
              />
            </span>
          </Disclaimer>

          <Disclaimer>
            <FormattedMessage
              defaultMessage="Bread Pay™ loans are made by Comenity Capital Bank, a Bread Financial™ company."
              description="Intro screen footer text"
              values={formattingValues}
            />
          </Disclaimer>
        </>
      )}
    </div>
  );
};

export const Disclaimer: FCWithChildren = ({ children }) => {
  return (
    <p className="text-xs leading-normal text-dark-blue-grey leading-5 mb-4">
      {children}
    </p>
  );
};
