import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { QuestionMark } from './svgs/QuestionMark';
import { Tooltip, TooltipHeading, TooltipParagraph } from './Tooltip';

interface EligibilityCheckHelpProps {
  dataTestID: string;
}

export const EligibilityCheckHelp: FunctionComponent<EligibilityCheckHelpProps> =
  ({ dataTestID }) => {
    const intl = useIntl();

    return (
      <Tooltip
        tip={
          <>
            <TooltipHeading>
              {intl.formatMessage({
                defaultMessage: 'Soft Inquiry',
                description: 'Eligibility credit score check tooltip title',
              })}
            </TooltipHeading>
            <TooltipParagraph>
              {intl.formatMessage({
                defaultMessage:
                  'A soft inquiry is used to check your eligibility, which won’t affect your credit score.',
                description:
                  'Eligibility credit score check tooltip description',
              })}
            </TooltipParagraph>
          </>
        }
        triggerClassName="inline-block px-1 align-text-top"
        dataTestID={dataTestID}
      >
        <QuestionMark
          aria-hidden="true"
          className="w-4 h-4 text-theme-primary"
        />
      </Tooltip>
    );
  };
